import React from "react"
import Layout from "../components/layout"

const Thanks = () => {
  return (
    <Layout>
      <div>
        <p>
          Speak soon.
        </p>
      </div>
    </Layout>
  )
}
export default Thanks
